.planDetail-top-section {
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 28px;
  margin-top: 1rem;
}

.edit-provision-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #eff2f7;
  color: black;
  border: none;
  font-size: 20px;
}
.edit-provision-button:hover {
  background-color: $gray-500;
}
.edit-provision-button:active {
  background-color: $gray-800 !important;
}

.edit-provision-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #34c38f;
  color: white;
  border: none;
  font-size: 20px;
}
.edit-provision-button-active:hover {
  background-color: $gray-500;
}
.edit-provision-button-active:active {
  background-color: $gray-800 !important;
}

.review-provision-button-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #34c38f;
  color: white;
  border: none;
  font-size: 20px;
}
.review-provision-button-active:active {
  background-color: $gray-800 !important;
}

.find-in-document-button {
  @extend .edit-provision-button;
  font-size: 10px;
}

.provision-category-header {
  background-color: #eff2f7 !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.1rem !important;
  font-size: 16px !important;
  border-bottom: none;
  align-content: center;
}
.provision-label-header-actions {
  @extend .provision-category-header;
  width: 86px !important;
}

.provision-category-header-first-row {
  @extend .provision-category-header;
  background-color: #e5e8ed !important;
}

.provision-label-header {
  background-color: #eff2f7 !important;
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
  font-size: 12px !important;
}

.provision-label-header-locations {
  width: 25% !important;
}

.edited-provision {
  background-color: #daf4eb !important;
  padding: 0.5rem;
}

.category-filter {
  background-color: white;
  border-color: darkgray;
  color: $gray-700;
  height: 30px;
  padding: 0px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  padding-top: 2px;
  width: 40%;
}

.category-filter:hover {
  background-color: $gray-300;
  border-color: $gray-900;
  color: $gray-900;
}

.category-filter:active {
  background-color: $gray-400 !important;
  border-color: $gray-900 !important;
  color: $gray-800 !important;
}

.tooltipFindInDocument {
  width: 300px;
}

.displayPDFLabel {
  margin-right: 0.4rem;
  font-weight: 600;
}

.showFullScreenLabel {
  margin-right: 0.2rem;
  font-weight: 600;
}

.setFullScreen {
  margin-right: 3rem;
  margin-left: 0px;
  font-size: 2rem;
  cursor: pointer;
}

.provisionsTableFullScreen::backdrop {
  background-color: white !important;
}

.provisionTableOptions {
  background-color: #eff2f7;
  margin: 1rem;
}

.answers-container {
  height: 60vh;
  overflow-y: auto;
  padding-right: 0px;
}

@media (display-mode: fullscreen) {
  .answers-container {
    height: 90vh;
  }
}

.answers-container-full {
  height: 57vh;
  overflow-y: auto;
  width: auto;
  margin-top: 0px !important;
}

@media (display-mode: fullscreen) {
  .answers-container-full {
    height: 90vh;
  }
}

.pdf-container {
  padding-left: 0px;
}

.provision-table {
  width: auto !important;
  margin-top: 0px !important;
  margin-right: 0px !important;
}
.provision-table-full {
  overflow-y: auto;
  width: auto !important;
}

.displayPDF {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.displayCategoriesFilter {
  margin-top: 0.3rem;
  margin-bottom: 0px !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.provision-table-comparison {
  table-layout: fixed;
  width: calc(100% - 32px);
  th {
    width: auto !important;
  }
}
